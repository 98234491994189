import { NumericInputDirective } from './numeric-input.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditCardFormatDirective } from './credit-card-format.directive';
import { PinMaskDirective } from './pin-mask.directive';

@NgModule({
  declarations: [
    CreditCardFormatDirective,
    NumericInputDirective,
    PinMaskDirective,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CreditCardFormatDirective,
    NumericInputDirective,
    PinMaskDirective,
  ],
})
export class SharedModule {}
