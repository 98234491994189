import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { CardFormComponent } from './forms/card/card.component';
import { SharedModule } from '../utilities/shared.module';
import { PinInputComponent } from './forms/pin-input/pin-input.component';
import { OtpInputComponent } from './forms/otp-input/otp-input.component';
import { PhoneInputComponent } from './forms/phone-input/phone-input.component';
import { UssdComponent } from './forms/ussd/ussd.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ButtonComponent,
    CardFormComponent,
    PinInputComponent,
    OtpInputComponent,
    PhoneInputComponent,
    UssdComponent,
  ],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: [
    ButtonComponent,
    CardFormComponent,
    PinInputComponent,
    OtpInputComponent,
    PhoneInputComponent,
    UssdComponent,
  ],
})
export class ComponentsModule {}
