import { AIPService } from 'src/app/services/aip.service';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaystackService } from 'src/app/services/paystack.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit, AfterViewInit {
  amount!: number;

  constructor(
    private paystackService: PaystackService,
    private aipService: AIPService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.error('success loaded >>>');
  }

  ngOnInit(): void {
    this.paystackService.displayAmount$.subscribe({
      next: value => {
        this.amount = value;
      },
    });
  }

  ngAfterViewInit(): void {
    if (!this.paystackService.transaction) {
      this.router.navigate(['/']);
      return;
    }
    setTimeout(() => {
      window.parent.postMessage(true, '*');
      this.document.location.href = AIPService.RedirectURL;
    }, 1000);
  }
}
