interface PaystackUSSD {
  bankName: string;
  code: string;
  shortBankName: string;
  payCode?: string;
}

export class PaystackUSSDModel {
  bankName!: string;

  shortBankName!: string;

  code!: string;

  payCode!: string;

  constructor({ bankName, code, shortBankName }: PaystackUSSD) {
    this.bankName = bankName;
    this.code = code;
    this.shortBankName = shortBankName;
  }
}

export class PaystackUSSDCodes {
  static allUSSDChannels = [
    new PaystackUSSDModel({
      bankName: 'Guaranty Trust Bank',
      shortBankName: 'GTBank',
      code: '737',
    }),
    new PaystackUSSDModel({
      bankName: 'Sterling Bank',
      shortBankName: 'Sterling Bank',
      code: '822',
    }),
    new PaystackUSSDModel({
      bankName: 'Zenith Bank',
      shortBankName: 'Zenith Bank',
      code: '966',
    }),
    new PaystackUSSDModel({
      bankName: 'United Bank of Africa',
      shortBankName: 'UBA',
      code: '919',
    }),
  ];
}
