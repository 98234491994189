<div class="flex w-full items-center justify-center pt-10">
  <div class="success-wrapper mx-auto w-full rounded bg-white py-5 text-center">
    <div class="flex flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="mb-4 h-16 w-16 text-green-500">
        <path
          fill="currentColor"
          d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm5.657 8.243l-7.536 7.536c-.37.371-.965.371-1.336 0l-3.536-3.535c-.371-.37-.371-.965 0-1.336s.965-.371 1.336 0l2.828 2.828 6.465-6.465c.37-.371.965-.371 1.336 0s.371.965 0 1.336z" />
      </svg>
      <p class="text-center text-2xl font-medium">Payment Successful</p>
      <p class="pt-2 text-base font-light text-secondary-500">
        You paid {{ amount | currency : 'NGN' : 'NGN ' : '1.0' }}
      </p>
    </div>
  </div>
</div>
