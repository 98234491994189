import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  setIcons() {
    this.iconRegistry.addSvgIcon(
      'paystack-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/paystack-logo.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'otp-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/otp-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'phone-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/phone-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'redirect-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/redirect-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'error-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/error-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'wallet-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/wallet-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'hashtag-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/hashtag-icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'bank-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/bank-icon.svg')
    );
    this.iconRegistry.addSvgIcon(
      'qr-icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/img/qr-icon.svg')
    );
  }
}
