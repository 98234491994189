export enum TransactionStatus {
  pending,
  success,
  failed,
  otp,
  pin,
  phone,
  _3DS,
  loading,
  error,
}

export enum Channel {
  card = 'card',
  bank = 'bank',
  transfer = 'bank_transfer',
  ussd = 'ussd',
  payattitude = 'payattitude',
  visaqr = 'qr',
}

export interface CardDetails {
  number: string;
  cvv: string;
  month: string;
  year: string;
  pin: string;
}

export interface CardValidation {
  isValid: boolean;
  result: {
    number: boolean;
    expiry: boolean;
    cvv: boolean;
  };
}

export interface ChargeResponse {
  status: string;
  auth: string;
  message: string;
  data: {
    auth: string;
  };
}
export interface PhoneNumberValidation {
  number?: string;
  telco?: string;
  isValid?: boolean;
}

export interface USSDShortcodeResponse {
  status: string;
  data: {
    reference: string;
    channel: string;
    code: string;
  };
}
