/* eslint-disable @typescript-eslint/no-explicit-any */
import { Channel } from '../interfaces/paystack';

export class PaystackChannelModel {
  name!: string;

  prettyName!: string;

  icon: string;

  constructor({ name, prettyName, icon }: PaystackChannel) {
    this.name = name;
    this.prettyName = prettyName;
    this.icon = icon;
  }

  static defaultChannels = [
    Channel.transfer,
    Channel.card,
    Channel.ussd,
    Channel.payattitude,
  ];
}

interface PaystackChannel {
  name: string;
  prettyName: string;
  icon: string;
}

export interface PaystackTransaction {
  email: string;
  merchant?: Merchant;
  status?: string;
  accessCode?: string;
  amount: number;
  domain?: string;
  isTest?: boolean;
  currency?: string;
  id?: number;
  channels: string[];
  label?: string;
  metadata?: object;
  cancel_action?: any;
  channel_options?: Channeloptions;
  hash?: any;
  remember_card?: boolean;
  card?: object;
  bankAccount?: object;
  mobileMoney?: object;
  ussd?: object;
  bankTransfer?: object;
  qr?: object;
  directDebit?: object;
  deviceFingerprint?: string;
  customer?: Customer;
  custom_filters?: any;
  isCharging?: boolean;
  isListening?: boolean;
  transactionType?: any;
  subscription?: any;
  planDetails?: any;
  log?: Log;
}

export class PaystackTransactionModel implements PaystackTransaction {
  email!: string;

  amount!: number;

  channels!: string[];

  currency!: string;

  constructor({
    email,
    amount,
    channels,
    currency,
  }: {
    email: string;
    amount: number;
    channels: string[];
    currency: string;
  }) {
    this.email = email;
    this.amount = amount;
    this.channels = channels;
    this.currency = currency;
  }
}

interface Log {
  start_time: number;
  time_spent: number;
  attempts: number;
  errors: number;
  success: boolean;
  mobile: boolean;
  input: any[];
  history: any[];
}

interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  metadata?: any;
  created_at: string;
  updated_at: string;
}

interface Channeloptions {
  ussd: string[];
}

interface Merchant {
  id: number;
  logo: string;
  name: string;
  key: string;
  object: object;
}
