import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconRegistryService } from './utilities/icon-registry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'aip-checkout-fe';

  private token!: string | null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iconService: IconRegistryService
  ) {
    this.iconService.setIcons();
  }

  ngOnInit(): void {
    console.error('app component >> ', this.router.url);
  }
}
