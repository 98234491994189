import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  InitiatePaymentWithTransferResponse,
  PaymentDetailsResponse,
  PaystackPayWithTransferResponse,
} from '../interfaces/api';
import { API } from '../constants/paths';
import { Observable, map, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AIPService {
  constructor(private http: HttpClient) {}

  private aipBaseURL = API.HOST + API.APP.aip;

  static RedirectURL = API.REDIRECT_URL;

  static CancelURL = API.CANCEL_URL;

  private _paymentReference!: string;

  private _bankTransferNUBAN!: InitiatePaymentWithTransferResponse;

  private _transactionDetails?: PaymentDetailsResponse;

  getPaymentDetails(reference: string): Observable<PaymentDetailsResponse> {
    const endpoint = this.aipBaseURL + API.routes.ref(reference);

    return this.http.get<ApiResponse<PaymentDetailsResponse>>(endpoint).pipe(
      map(response => response.data),
      retry(3)
    );
  }

  getBankTransferNUBAN(
    reference: string
  ): Observable<InitiatePaymentWithTransferResponse> {
    // return this.mock_getBankTransferNUBAN(reference);

    // todo:  uncomment code below
    const endpoint = this.aipBaseURL + API.routes.initiatePaymentWithTransfer;
    const requestBody = {
      ref: reference,
    };
    return this.http
      .post<ApiResponse<{ account: InitiatePaymentWithTransferResponse }>>(
        endpoint,
        requestBody
      )
      .pipe(
        map(response => {
          this._bankTransferNUBAN = response.data.account;
          return this._bankTransferNUBAN;
        })
      );
  }

  paystackPayWithTransfer(
    reference: string
  ): Observable<PaystackPayWithTransferResponse> {
    const endpoint =
      this.aipBaseURL + API.routes.initiatePaystackPayWithTransfer;
    const requestBody = {
      ref: reference,
    };
    return this.http
      .post<ApiResponse<PaystackPayWithTransferResponse>>(endpoint, requestBody)
      .pipe(
        map(response => {
          return response.data;
        })
      );
  }

  getDetailsByRef(
    ref: string
  ): Observable<ApiResponse<PaymentDetailsResponse>> {
    const endpoint = this.aipBaseURL + API.routes.get_details_by_ref + ref;
    return this.http.get<ApiResponse<PaymentDetailsResponse>>(endpoint);
  }

  get transactionDetails(): PaymentDetailsResponse | undefined {
    return this._transactionDetails;
  }

  set transactionDetails(details: PaymentDetailsResponse | undefined) {
    this._transactionDetails = details;
  }

  get paymentReference(): string {
    return this._paymentReference;
  }

  set paymentReference(ref: string | null) {
    if (!ref) {
      return;
    }
    this._paymentReference = this.removeLeadingSlash(ref);
  }

  get bankTransferNUBAN() {
    return this._bankTransferNUBAN;
  }

  private removeLeadingSlash(str: string): string {
    if (str.charAt(0) === '/') {
      return str.substr(1);
    }
    return str;
  }
}
