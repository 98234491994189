import { environment } from 'src/environments/environment';

export class API {
  static HOST = environment.host;

  static REDIRECT_URL = environment.redirectUrl;

  static CANCEL_URL = environment.cancelUrl;

  static APP = {
    cis: 'cis/',
    aip: 'aip/',
  };

  static routes = {
    ref: (reference: string) => `ref/${reference}`,
    initiatePaymentWithTransfer: 'initiatePaymentWithTransfer',
    initiatePaystackPayWithTransfer: 'payWithTempAccount',
    get_details_by_ref: 'ref/',
  };
}
