/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CardDetails,
  Channel,
  ChargeResponse,
  USSDShortcodeResponse,
} from 'src/app/interfaces/paystack';
import { Injectable } from '@angular/core';
import { Transaction, Card } from '@paystack/checkout-js';
import { PaystackUSSDCodes } from '../utilities/paystack/paystack-ussd';
import {
  PaystackChannelModel,
  PaystackTransaction,
} from '../models/paystack-model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaystackService {
  private _transaction: any;

  private _displayAmount = new BehaviorSubject(0);

  // async initializeTransaction(transactionData: any) {
  //   try {
  //     this._transaction = await Transaction.request(transactionData);
  //     console.error('transaction in paystack service >> ', this._transaction);
  //     // eslint-disable-next-line no-empty
  //   } catch (error) {}
  // }

  async initializeTransactionWithAccessCode(accessCode: string) {
    try {
      this._transaction = await Transaction.requestWithAccessCode(accessCode);
      this.displayAmount = this._transaction.amount / 100;
      console.error('transaction in paystack service >> ', this._transaction);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  get displayAmount$(): Observable<number> {
    return this._displayAmount.asObservable();
  }

  set displayAmount(amount: number) {
    this._displayAmount.next(amount);
  }

  validateCard(cardDetails: CardDetails) {
    return Card.validate(cardDetails);
  }

  async setCard(cardDetails: CardDetails) {
    await this._transaction.setCard(cardDetails);
  }

  async chargeCard(): Promise<ChargeResponse> {
    const chargeResponse: ChargeResponse = await this._transaction.chargeCard();
    console.error(`Charge Response >>>> `, chargeResponse);
    return chargeResponse;
  }

  async authenticateCard(otp: string) {
    const authResponse = await this._transaction.authenticateCard(otp);
    return authResponse;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async listenFor3DSCharge(callback: any) {
    await this._transaction.listenFor3DSCharge(callback);
  }

  async requeryTransaction(): Promise<ChargeResponse> {
    const chargeResponse: ChargeResponse = await this._transaction.requery();
    return chargeResponse;
  }

  async getManagedAccount(): Promise<ChargeResponse> {
    const chargeResponse: ChargeResponse =
      await this._transaction.getManagedAccount();
    return chargeResponse;
  }

  async getUSSDShortcode(channel: string): Promise<USSDShortcodeResponse> {
    const response: USSDShortcodeResponse =
      await this._transaction.getUSSDShortcode({
        channel,
      });
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async listenForUSSDCharge(code: string, callback: any) {
    await this._transaction.listenForUSSDCharge({ channel: code }, callback);
  }

  get transaction(): PaystackTransaction {
    return this._transaction;
  }

  set transaction(transaction: PaystackTransaction) {
    this._transaction = transaction;
    this.displayAmount = this._transaction.amount / 100;
  }

  get USSDChannels() {
    const allUSSDChannels = PaystackUSSDCodes.allUSSDChannels;
    return allUSSDChannels.filter(channel =>
      this._transaction.channel_options.ussd.includes(channel.code)
    );
  }

  fromPaystackChannelsList(channelList: string[]) {
    console.error('channelList >> ', channelList);
    channelList = [...channelList];
    let newChannelList: (PaystackChannelModel | undefined)[] = [];
    // let modifiedChannel: PaystackChannelModel;

    newChannelList = channelList.map(channel => {
      switch (channel) {
        case Channel.transfer:
          return new PaystackChannelModel({
            name: channel,
            prettyName: 'Transfer',
            icon: 'bank-icon',
          });
        case Channel.card:
          return new PaystackChannelModel({
            name: channel,
            prettyName: 'Card',
            icon: 'wallet-icon',
          });
        case Channel.ussd:
          return new PaystackChannelModel({
            name: channel,
            prettyName: 'USSD',
            icon: 'hashtag-icon',
          });
        case Channel.visaqr:
          // return new PaystackChannelModel({
          //   name: channel,
          //   prettyName: 'Visa QR',
          //   icon: 'qr-icon',
          // });
          return undefined;
        default:
          return undefined;
      }
    });

    return newChannelList.filter(
      channel => channel !== undefined
    ) as PaystackChannelModel[];
  }
}
