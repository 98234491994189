export const environment = {
  production: false,
  host: 'https://leapbe.asharami-innovations.io/',
  businessId: '743b7b83c026caad04b0',
  paystackPublicKey: 'pk_test_1b72704ceb2ce73d3d98d5408b6f268a67365e4b',
  redirectUrl: 'https://leap.asharami-innovations.io/',
  faroName: 'Leap',
  faroUrl:
    'https://faro-collector-prod-us-central-0.grafana.net/collect/c7fc34f20b6581167d0ca36268c0d26e',
  cancelUrl: 'https://aip.asharami-innovations.io/cancel',
};
